<template>
  <!-- 错误信息页面 -->
  <div id="error">
    <error-info :locale="locale" :type="type"></error-info>
  </div>
</template>

<script>
import ErrorInfo from '@/components/ErrorInfo.vue';

export default {
  components: {
    "error-info": ErrorInfo
  },
  data() {
    return {
      locale: "", // 语系、地区
      type: "", // 错误类型
    }
  },
  created() {
    this.$route.params ? this.locale = this.$route.params.locale : this.locale = "chn";
    this.$route.params ? this.type = this.$route.params.type : this.type = "generateFail";
  }
}
</script>