<template>
  <!-- 收银台页面 -->
  <div id="register">
    <cash-register :locale="locale" :orderInfoString="orderInfoString"></cash-register>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import CashRegister from "@/components/CashRegister.vue";

export default {
  components: {
    "cash-register": CashRegister
  },
  data() {
    return {
      locale: "", // 语系、地区
      orderInfoString: "" // 订单资讯字串
    }
  },
  created() {
    this.$route.params ? this.locale = this.$route.params.locale : this.locale = "chn";
    this.$route.query.base64 ? this.orderInfoString = Base64.decode(this.$route.query.base64) : this.orderInfoString = "";

    if (this.orderInfoString === "") {
      this.$router.push({ name: "ErrorView", params: { locale: this.locale, type: "generateFail" } });
    }
  },
}
</script>