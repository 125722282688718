<template>
  <div class="page_contain">
    <div class="information">
      <div class="wrapper">
        <h2>{{ errorTitle }}</h2>
        <img src="../assets/image/icon_error.png" alt="error">
        <article>{{ errorMessage }}</article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 语系、地区
    locale: {
      type: String,
      required: true
    },
    // 错误类型
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorTitle: "", // 错误标题
      errorMessage: "" // 错误讯息
    }
  },
  methods: {
    /**
     * 初始化
     */
    init: function() {
      this.$i18n.locale = this.locale; // 设定语系

      switch (this.type) {
        // 生成失败
        case "generateFail":
          this.errorTitle = this.$t("Error.Title.GenerateFail");
          this.errorMessage = this.$t("Error.Message.GenerateFail");
          break;

        // 订单过期
        case "expired":
          this.errorTitle = this.$t("Error.Title.Expired");
          this.errorMessage = this.$t("Error.Message.Expired");
          break;
      }
    }
  },
  created() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

* {
  @include base-style;
}

.page_contain {
  display: block;
  width: 100%;

  .information {
    display: block;
    width: 100%;
    margin: 0.15rem auto;
    padding: 0.15rem;

    .wrapper {
      @include card-box;

      h2 {
        display: block;
        width: 100%;
        color: #f84848;
        font-size: 0.32rem;
        line-height: 0.6rem;
        text-align: center;
        margin: 0.15rem auto;
      }

      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto;
      }

      article {
        display: block;
        width: 100%;
        color: $font-grey;
        font-size: 0.28rem;
        line-height: 0.4rem;
        text-align: center;
        margin: 0.3rem auto 0.2rem;
      }
    }
  }  
}
</style>