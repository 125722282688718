<template>
  <div class="dialog_model" :class="themeClass">
    <div class="dialog">
      <!-- 提示確認彈窗 -->
      <div class="container question" v-if="dialogInfo.type == 'question'">
        <h2>{{ dialogInfo.title }}</h2>
        <article>{{ dialogInfo.msg }}</article>
        <div class="btns">
          <button class="cancel_btn" @click="dialogCancel">{{ $t("Button.Cancel") }}</button>
          <button class="submit_btn" @click="dialogSubmit">{{ $t("Button.Submit") }}</button>
        </div>
      </div>

      <!-- 成功提示彈窗 -->
      <div class="container success" v-if="dialogInfo.type == 'success'">
        <h2>{{ dialogInfo.title }}</h2>
        <img src="../assets/image/icon_success.png" alt="Success">
        <article>{{ dialogInfo.msg }}</article>
        <button @click="dialogSure">{{ $t("Button.Sure") }}</button>
      </div>

      <!-- 失敗提示彈窗 -->
      <div class="container fail" v-if="dialogInfo.type == 'fail'">
        <h2>{{ dialogInfo.title }}</h2>
        <img src="../assets/image/icon_error.png" alt="Fail">
        <article>{{ dialogInfo.msg }}</article>
        <button @click="dialogClose">{{ $t("Button.Close") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 语系、地区
    locale: {
      type: String,
      default: ""
    },
    // 彈窗資訊
    dialogInfo: {
      type: Object,
      default: () => {
        return {
          type: "question",
          title: "",
          msg: "",
        }
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    /**
     * 詢問彈窗 取消按鈕
     */
    dialogCancel: function() {
      this.$emit("dialogCancel");
    },
    /**
     * 詢問彈窗 確認按鈕
     */
    dialogSubmit: function() {
      this.$emit("dialogSubmit");
    },
    /**
     * 成功提示彈窗 確認按鈕
     */
    dialogSure: function() {
      this.$emit("dialogSure");
    },
    /**
     * 失敗提示彈窗 關閉按鈕
     */
    dialogClose: function() {
      this.$emit("dialogClose");
    },
    /**
     * 初始化
     */
    init: function() {
      this.$i18n.locale = this.locale;
      this.themeClass = `${this.locale}-theme`; // 设定主题样式
    }
  },
  created() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";

* {
  @include base-style;
}

.dialog_model {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 半透明的背景色 */
  backdrop-filter: blur(10px); /* 模糊效果 */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .dialog {
    display: block;
    width: 80%;
    background: #fff;
    border-radius: $border-radius;
    padding: 0.15rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width: 767px) {
      width: 80%;
    }

    @media screen and (min-width: 768px) {
      width: 750px;
    }

    .container {
      h2 {
        display: block;
        width: 100%;
        color: var(--theme-color);
        font-size: 0.4rem;
        text-align: center;
        line-height: 0.8rem;
      }

      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 0.4rem auto;
      }

      article {
        display: block;
        width: 100%;
        color: $font-black;
        font-size: 0.28rem;
        text-align: center;
        line-height: 0.4rem;
        white-space: normal;
        overflow-wrap: break-word;
      }

      .btns {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.3rem;

        button {
          display: block;
          width: 45%;
          border-radius: $border-radius;
          border: 1px solid $border-color;
          background: transparent;
          text-align: center;
          line-height: 0.6rem;
        }

        button.cancel_btn {
          color: $font-grey;
        }

        button.submit_btn {
          border: 1px solid var(--theme-color);
          background: var(--theme-color);
          color: #fff;
        }
      }
    }

    .container.success {
      button {
        display: block;
        width: 45%;
        border-radius: $border-radius;
        border: 1px solid var(--theme-color);
        background: var(--theme-color);
        color: #fff;
        text-align: center;
        line-height: 0.6rem;
        margin: 0.2rem auto 0;
      }
    }

    .container.fail {
      h2 {
        color: $font-red;
      }

      button {
        display: block;
        width: 45%;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        background: transparent;
        color: $font-grey;
        text-align: center;
        line-height: 0.6rem;
        margin: 0.2rem auto 0;
      }
    }
  }
}
</style>