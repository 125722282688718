import { createRouter, createWebHashHistory } from "vue-router";
import RegisterView from "@/views/RegisterView.vue";
import ErrorView from "@/views/ErrorView.vue";

const routes = [
  {
    path: "/:locale?",
    name: "RegisterView",
    component: RegisterView
  },
  {
    path: "/:locale/:type",
    name: "ErrorView",
    component: ErrorView
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;