<template>
  <transition name="fade">
    <div class="message_toast">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.message_toast {
  display: inline-block;
  max-width: 80%; /* 限制最大宽度，确保不会过宽 */
  border-radius: 0.1rem; /* 圆角 */
  color: #fff;
  font-size: 0.28rem;
  line-height: 0.4rem;
  padding: 0.05rem 0.2rem; /* 增加左右 padding 使 Toast 稍微大于文字 */
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%); /* 水平居中 */
  text-align: center;
  z-index: 999;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>