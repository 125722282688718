import { createI18n } from "vue-i18n";
import chn from "./json/chn.json"; // 中國文本
import ind from "./json/ind.json"; // 印度文本
import vnm from "./json/vnm.json"; // 越南文本

const i18n = new createI18n({
  locale: "chn",
  messages: {
    chn,
    ind,
    vnm
  },
  fallbackLocale: "chn"
});

export { i18n };